import ajaxService, { AjaxError } from "AjaxService";
import errorHandler from "ErrorHandler";
import global from "Global";
import log, { DefaultLogTag, type LogData } from "Log";

interface CspReport {
  cspReport: {
    blockedUri: string;
    columnNumber: number;
    disposition: string;
    documentUri: string;
    effectiveDirective: string;
    lineNumber: number;
    originalPolicy: string;
    referrer: string;
    sourceFile: string;
    statusCode: number;
    violatedDirective: string;
  };
  currentUri: string;
  userAgent: string;
  glowLogs: LogData[];
}

async function sendReportAsync(violation: CspReport): Promise<void> {
  try {
    const ajaxSettings = {
      type: "POST",
      url: `${global.rootPath}csp/report`,
      data: JSON.stringify(violation),
      contentType: "application/json",
    };
    await ajaxService.ajaxAsync(ajaxSettings);
  } catch (error) {
    //Rate limit exceeded, ignoring 429 error
    if (error instanceof AjaxError && error.status === 429) {
      return;
    }
    errorHandler.reportError(error);
  }
}

function getTruncatedGlowLogs(): LogData[] {
  const maxLogSize = 1024 * 2;
  const logs = log.getLogs()[DefaultLogTag] || [];
  let size = 0;
  let startIndex = logs.length;
  for (let i = logs.length - 1; i >= 0; i--) {
    size += logs[i].message.length;
    if (size > maxLogSize) {
      break;
    }
    startIndex--;
  }
  return logs.slice(startIndex);
}

export default function cspBootstrapper(): void {
  if (global.sendCspReport) {
    window.document.addEventListener("securitypolicyviolation", async (event) => {
      const violation: CspReport = {
        cspReport: {
          blockedUri: event.blockedURI,
          columnNumber: event.columnNumber,
          disposition: event.disposition,
          documentUri: event.documentURI,
          effectiveDirective: event.effectiveDirective,
          lineNumber: event.lineNumber,
          originalPolicy: event.originalPolicy,
          referrer: event.referrer,
          sourceFile: event.sourceFile,
          statusCode: event.statusCode,
          violatedDirective: event.violatedDirective,
        },
        currentUri: window.location.href,
        userAgent: navigator.userAgent,
        glowLogs: getTruncatedGlowLogs(),
      };
      await sendReportAsync(violation);
    });
  }
}
