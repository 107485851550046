import ajaxService from 'AjaxService';
import alertDialogService from 'AlertDialogService';
import appConfig from 'AppConfig';
import captionService from 'CaptionService';
import entityMappingService from 'EntityMappingService';
import global from 'Global';
import serverMessageQueue from 'ServerMessageQueue';
import Storage from 'Storage';
import userSession from 'UserSession';
import ko from 'knockout';
import 'knockout-projections';
import _ from 'underscore';

function FavoritesService() {
	/*! StartNoStringValidationRegion Local storage key */
	this.favoritesStorageKey = `Favorites${global.rootPathForModule}`;
	this.recentsStorageKey = `Recents${global.rootPathForModule}`;
	/*! EndNoStringValidationRegion */
	this.currentRecent = ko.observable();
	this.hasError = ko.observable(false);

	this._isLoadingFavorites = false;
	this._isLoadingRecents = false;

	this.favorites = ko.lazyObservableArray(() => loadFavoritesAsync(this));
	this._favoritesLoader = undefined;

	this.recents = ko.lazyObservableArray(() => loadRecentsAsync(this));
	this._recentsLoader = undefined;

	this.pinnedFavorites = ko.pureComputed(() => getPinnedFavorites(this));
	this.isLoading = ko.pureComputed(() => isLoading(this));
	this._favoritesStorageChangedHandler = _.debounce(this.favorites.refresh, appConfig.storageChangedTimeout);
	this._recentsStorageChangedHandler = _.debounce(this.recents.refresh, appConfig.storageChangedTimeout);
}

function loadFavoritesAsync(service) {
	service._favoritesLoader = (async () => {
		try {
			if (userSession.isLoggedOn() && !userSession.isInvalidated()) {
				const stored = service._getFavoritesFromStorage();
				if (stored) {
					service.favorites(stored.filter((favorite) => !isAbsoluteUri(favorite.Uri)));
				} else if (!service._isLoadingFavorites) {
					service._isLoadingFavorites = true;
					const favoritesUri = `${global.serviceUri}api/favorites/GetFavorites`;

					try {
						const loadedFavorites = await ajaxService.getAsync(favoritesUri, {
							portalCode: global.moduleName,
						});

						if (loadedFavorites) {
							service.favorites(loadedFavorites.map(service.getLinkItem, service));
						} else {
							service.favorites([]);
						}
					} catch (error) {
						handleAjaxError(service, true, error);
					} finally {
						service._isLoadingFavorites = false;
					}
				}
			}
		} finally {
			service._favoritesLoader = undefined;
		}
	})();

	return service._favoritesLoader;
}

FavoritesService.prototype._loadFavoritesAsync = async function () {
	this.favorites();
	await this._favoritesLoader;
};

function loadRecentsAsync(service) {
	service._recentsLoader = (async () => {
		try {
			if (userSession.isLoggedOn() && !userSession.isInvalidated()) {
				const stored = service._getRecentsFromStorage();
				if (stored) {
					service.recents(stored.filter((recent) => !recent.isInvalid && !isAbsoluteUri(recent.Uri)));
				} else if (!service._isLoadingRecents) {
					service._isLoadingRecents = true;
					const recentsUri = `${global.serviceUri}api/favorites/GetRecents`;
					const query = { portalCode: global.moduleName };
					try {
						const loadedRecents = await ajaxService.getAsync(recentsUri, query);

						if (loadedRecents) {
							const recentLinks = loadedRecents.map(service.getLinkItem, service);
							service.recents(recentLinks.filter((recent) => !recent.isInvalid));
						} else {
							service.recents([]);
						}
					} catch (error) {
						handleAjaxError(service, true, error);
					} finally {
						service._isLoadingRecents = false;
					}
				}
			}
		} finally {
			service._recentsLoader = undefined;
		}
	})();

	return service._recentsLoader;
}

FavoritesService.prototype._loadRecentsAsync = async function () {
	this.recents();
	await this._recentsLoader;
};

function getPinnedFavorites(service) {
	return ko.utils.arrayFilter(service.favorites(), (favorite) => {
		return favorite.isPinned();
	});
}

function isLoading(service) {
	return !service.favorites.loaded() || !service.recents.loaded();
}

function isInvalidEntity(item) {
	return !!item.EntityType && !entityMappingService.hasInterfaceName(item.EntityType);
}

FavoritesService.prototype.attachEventHandlers = function () {
	Storage.off(this.favoritesStorageKey, this._favoritesStorageChangedHandler).on(
		this.favoritesStorageKey,
		this._favoritesStorageChangedHandler,
	);
	Storage.off(this.recentsStorageKey, this._recentsStorageChangedHandler).on(
		this.recentsStorageKey,
		this._recentsStorageChangedHandler,
	);

	if (this._subscription) {
		this._subscription.dispose();
	}

	this._subscription = userSession.onIsLoggedOnChanged(() => {
		this.reset();
	});
};

FavoritesService.prototype._getFavoritesFromStorage = function () {
	const storedData = Storage.get(this.favoritesStorageKey);
	if (storedData && storedData.favorites) {
		return storedData.favorites.map(this.getLinkItem, this);
	}
};

FavoritesService.prototype._getRecentsFromStorage = function () {
	const storedData = Storage.get(this.recentsStorageKey);
	if (storedData && storedData.recents) {
		return storedData.recents.map(this.getLinkItem, this);
	}
};

FavoritesService.prototype.reset = function () {
	Storage.remove(this.favoritesStorageKey);
	Storage.remove(this.recentsStorageKey);
	this.favorites.refresh();
	this.recents.refresh();
};

FavoritesService.prototype._saveFavoritesToStorage = function () {
	Storage.set(this.favoritesStorageKey, { favorites: this.favorites().map(linkItemPostContent) });
};

FavoritesService.prototype._saveRecentsToStorage = function () {
	Storage.set(this.recentsStorageKey, { recents: this.recents().map(linkItemPostContent) });
};

FavoritesService.prototype.addToFavoritesAsync = async function (recent) {
	if (this.isDisabled() || !recent) {
		return;
	}

	await this._loadFavoritesAsync();

	const favorites = this.favorites();
	if (favorites.length >= 12) {
		alertDialogService.errorWithOKButtonAsync(
			captionService.getString(
				'cb23bffc-5729-4d7c-b96d-7076196626c2',
				'Favorites list full. Please remove unwanted items before adding new ones.',
			),
			captionService.getString('f44f5d10-5443-4771-a91a-fd5a82d4ff9e', 'Favorites list full'),
		);
		return;
	}

	if (favorites.find(getLinkItemPredicate(recent))) {
		return this.reset();
	}

	const newFavorite = this.getLinkItem(recent);
	newFavorite.LinkType = this.LinkTypes.Favorites;
	newFavorite.ShortcutIndex(favorites.length + 1);
	this.favorites.push(newFavorite);

	const uri = `${global.serviceUri}api/favorites/AddToFavorites`;

	queueServerMessage(uri, newFavorite);
	this._saveFavoritesToStorage();
};

FavoritesService.prototype.removeFromFavoritesAsync = async function (favorite) {
	if (this.isDisabled() || !favorite) {
		return;
	}

	await this._loadFavoritesAsync();

	const favoriteToRemove = this.getLinkItem(favorite);
	if (!this.favorites.remove(getLinkItemPredicate(favoriteToRemove)).length) {
		return this.reset();
	}

	const uri = `${global.serviceUri}api/favorites/RemoveFromFavorites`;

	queueServerMessage(uri, favoriteToRemove);

	updateShortcutIndices(this.favorites());
	this._saveFavoritesToStorage();
};

FavoritesService.prototype.reorderFavoritesAsync = async function (favorite, newShortcut) {
	if (this.isDisabled() || !favorite || favorite.ShortcutIndex() === newShortcut) {
		return;
	}

	await this._loadFavoritesAsync();

	if (!this.favorites.remove(getLinkItemPredicate(favorite)).length) {
		return this.reset();
	}

	this.favorites.splice(newShortcut - 1, 0, favorite);

	const uri = `${global.serviceUri}api/favorites/ReorderFavorites?newShortcut=${newShortcut}`;

	queueServerMessage(uri, favorite);

	updateShortcutIndices(this.favorites());
	this._saveFavoritesToStorage();
};

FavoritesService.prototype.addToRecentsAsync = async function (recent) {
	const newRecent = this.getLinkItem(recent);
	this.currentRecent(newRecent);

	if (this.isDisabled() || !newRecent || isAbsoluteUri(newRecent.Uri)) {
		return;
	}

	await this._loadRecentsAsync();

	this.recents.remove(getLinkItemPredicate(newRecent));
	this.recents.unshift(newRecent);

	const uri = `${global.serviceUri}api/favorites/AddToRecents`;

	queueServerMessage(uri, newRecent);
	this._saveRecentsToStorage();
};

function isAbsoluteUri(uri) {
	try {
		return Boolean(new URL(uri));
	} catch (e) {
		return false;
	}
}

FavoritesService.prototype.togglePinAsync = async function (favorite) {
	if (this.isDisabled() || !favorite || !favorite.isPinned) {
		return;
	}

	await this._loadFavoritesAsync();

	favorite.isPinned(!favorite.isPinned());

	const uri = `${global.serviceUri}api/favorites/TogglePin`;

	queueServerMessage(uri, favorite);

	favorite.LinkType =
		favorite.LinkType === this.LinkTypes.Favorites ? this.LinkTypes.PinnedFavorites : this.LinkTypes.Favorites;
	this._saveFavoritesToStorage();
};

FavoritesService.prototype.addCurrentToFavoritesAsync = async function () {
	await this.addToFavoritesAsync(this.currentRecent());
};

FavoritesService.prototype.removeCurrentFromFavoritesAsync = async function () {
	await this.removeFromFavoritesAsync(this.currentRecent());
};

FavoritesService.prototype.isDisabled = function () {
	return this.hasError() || !userSession.isLoggedOn();
};

function getLinkItemPredicate(linkItemOrData) {
	const uri = linkItemOrData.Uri ? linkItemOrData.Uri.toUpperCase() : '';
	const entityType = linkItemOrData.EntityType || '';
	const entityPK = linkItemOrData.EntityPK || null;

	return (x) => x.Uri.toUpperCase() === uri && x.EntityType === entityType && x.EntityPK === entityPK;
}

function queueServerMessage(uri, linkItem) {
	serverMessageQueue.queueMessage({
		verb: 'POST',
		uri,
		data: linkItemPostContent(linkItem),
	});
}

function linkItemPostContent(linkItem) {
	return {
		PortalCode: linkItem.PortalCode,
		LinkType: linkItem.LinkType,
		Uri: linkItem.Uri,
		Title: linkItem.Title,
		EntityType: linkItem.EntityType,
		EntityPK: linkItem.EntityPK,
		ShortcutIndex: linkItem.ShortcutIndex(),
	};
}

function updateShortcutIndices(favorites) {
	for (let i = 0; i < favorites.length; i++) {
		favorites[i].ShortcutIndex(i + 1);
	}
}

function handleAjaxError(service, displayNotification, error) {
	if (error) {
		if (error.status === 412) {
			if (displayNotification) {
				alertDialogService.errorWithOKButtonAsync(
					captionService.getString(
						'd29ac4f6-17b7-4157-8731-9a327bcf1b56',
						'Your action could not be completed because another session has modified your jump items while you have been working. They have been reloaded if possible. Please try again or contact your Administrator if the error persists.',
					),
					captionService.getString('d52c1e28-f76e-4084-b8e0-1fa5e4ba03e1', 'Jump Items reloaded'),
				);
			}
			service.reset();
		} else {
			service.hasError(true);
			throw error;
		}
	}
}

FavoritesService.prototype.getLinkItem = function (data) {
	return {
		PortalCode: data.PortalCode || global.moduleName.toUpperCase(),
		LinkType: data.LinkType || '',
		Uri: data.Uri || '',
		Title: data.Title || '',
		EntityType: data.EntityType || '',
		EntityPK: data.EntityPK || null,
		ShortcutIndex: ko.observable(ko.unwrap(data.ShortcutIndex) || 0),
		isFavorite: ko.pureComputed(isFavorite.bind(null, this.favorites, data)),
		isPinned: ko.observable(data.LinkType === this.LinkTypes.PinnedFavorites),
		isInvalid: isInvalidEntity(data),
	};
};

function isFavorite(favoritesObservable, data) {
	const favorites = favoritesObservable();
	if (favorites && favoritesObservable.loaded()) {
		return !!favorites.find(getLinkItemPredicate(data));
	}
	return false;
}

FavoritesService.prototype.LinkTypes = {
	Favorites: 'GFV',
	PinnedFavorites: 'GFP',
	RecentEntities: 'GRE',
	RecentFormFlows: 'GRF',
	RecentPages: 'GRP',
};

export default new FavoritesService();
