import { DeferredPromise } from "DeferredPromise";
import { delayAsync } from "DelayedPromise";
import MessageBus, { MessageType } from "MessageBus";

/*! SuppressStringValidation selector of indicator bar */
export const busyStateChangedType = new MessageType<boolean>("BusyStateChanged");
const delayInMs = 500;
const messageBus = new MessageBus();
let activeTracker: Tracker | undefined;
let slidingTimeoutId: ReturnType<typeof setTimeout>;
let errorTracker: DeferredPromise<void> | undefined;

/**
 * Subscribe to global busy state changes.
 *
 * @param handler Function called when the busy state changes.
 * @returns Function to unsubscribe from busy state changes.
 */
export function onBusyStateChanged(handler: (arg: boolean) => void): () => void {
  const id = messageBus.subscribe(busyStateChangedType, handler);

  return () => {
    messageBus.unsubscribe(busyStateChangedType, id);
  };
}

/**
 * Wait for the busy state to be idle, or exit early if the application enters an error state.
 *
 * @param delay Buffer time to wait for any more promises to be added to the queue.
 * @returns Promise that is resolved when the state is idle or if the application enters an error state.
 */
export async function waitForIdleAsync(delay: number = delayInMs): Promise<void> {
  // Don't wait if no tracker was set up.
  if (!activeTracker) {
    return;
  }

  // Wait for all queued promises, a delay timeout, or the error state checker promise.
  await Promise.race([Promise.allSettled([...activeTracker.queue]), waitForErrorAsync(), delayAsync(delayInMs)]);

  // If the queue is cleared, wait.
  if (activeTracker.queue.size === 0) {
    await delayAsync(delay);
  }

  // If some other promises were queued during the delay
  // or the awaiter was cancelled due to a timeout, queue again.
  if (activeTracker.queue.size > 0) {
    await waitForIdleAsync(delay);
  }
}

/**
 * Track the busy state for a promise. This can be used to signal to the GUI that
 * there is a background process that may block some actions.
 *
 * @param promise Promise to track.
 * @returns The input promise with tracking enabled.
 */
export async function trackBusyStateAsync<T>(promise: Promise<T>): Promise<T> {
  if (!activeTracker) {
    activeTracker = new Tracker();
  }

  const tracker = activeTracker;

  // eslint-disable-next-line rulesdir/prefer-async-await
  promise = promise.finally(() => {
    dequeue(tracker, promise);
  });

  await enqueueAsync(tracker, promise);
  return promise;
}

/**
 * Executes the provided asynchronous action and handles the error state.
 *
 * If the application is in an error state, it rejects the error tracker with a message.
 * After the action is executed, the error tracker is reset to undefined.
 *
 * @template T - The type of the result returned by the action.
 * @param {() => Promise<T>} action - The asynchronous action to be executed.
 * @returns {Promise<T>} A promise that resolves with the result of the action.
 */
export function withErrorStateHandling<T>(action: () => Promise<T>): Promise<T> {
  /*! SuppressStringValidation error message */
  errorTracker?.reject(new GlobalBusyStateError("The application is in an error state."));

  return (async (): Promise<T> => {
    try {
      const result = await action();
      return result;
    } finally {
      errorTracker = undefined;
    }
  })();
}

/**
 * Create a sub tracker and suspend the current busy state tracker. This is typically used
 * for modal dialogs that can interrupt the busy state on the main page and allow user to
 * interact with the dialog. However we continue to track the busy state for promises created
 * by the dialog itself using a sub tracker.
 *
 * @returns
 *   Function to stop the sub tracker and resume the parent tracker.
 *   This function should be called when the dialog is closed.
 *   When the function returns undefined the invoker does not need to stop the sub tracker.
 */
export async function createSubTrackerAsync(): Promise<(() => Promise<void>) | undefined> {
  if (!activeTracker || activeTracker.queue.size === 0) {
    return;
  }

  await notifySubscribersAsync(activeTracker, false);
  const tracker = new Tracker(activeTracker);
  activeTracker = tracker;
  return () => disposeSubTrackerAsync(tracker);
}

/**
 * Intended to be thrown when the application enters an error state, allowing awaiting promises to be rejected with this error.
 */
export class GlobalBusyStateError extends Error {
  override readonly name = "GlobalBusyStateError";
}

async function disposeSubTrackerAsync(tracker: Tracker): Promise<void> {
  if (tracker === activeTracker && tracker.parentTracker) {
    await waitForIdleAsync(0);
    activeTracker = tracker.parentTracker;
    await notifySubscribersAsync(activeTracker, activeTracker.queue.size > 0);
  }
}

class Tracker {
  queue: Set<Promise<unknown>> = new Set();
  constructor(public readonly parentTracker?: Tracker) {}
}

async function notifySubscribersAsync(tracker: Tracker, busy: boolean): Promise<void> {
  if (tracker === activeTracker) {
    await messageBus.publishAsync(busyStateChangedType, busy);
  }
}

async function enqueueAsync(tracker: Tracker, promise: Promise<unknown>): Promise<void> {
  const shouldNotify = tracker.queue.size === 0;
  tracker.queue.add(promise);

  if (shouldNotify) {
    await notifySubscribersAsync(tracker, true);
  }
}

function dequeue(tracker: Tracker, promise: Promise<unknown>): void {
  tracker.queue.delete(promise);

  if (slidingTimeoutId) {
    clearTimeout(slidingTimeoutId);
  }

  slidingTimeoutId = setTimeout(async () => {
    if (tracker.queue.size === 0) {
      await notifySubscribersAsync(tracker, false);
    }
  }, delayInMs);
}

function waitForErrorAsync(): Promise<void> {
  if (!errorTracker) {
    errorTracker = new DeferredPromise<void>();
  }

  return errorTracker.promise;
}
